<template>
    <div class="main">
		<keep-alive :include="keepAlive">
			<router-view></router-view>
		</keep-alive>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
		keepAlive () {
			return this.$store.state.user.cacheList
		}
	},
    methods: {}
}
</script>

<style lang="scss" scoped>
</style>
